import * as yup from 'yup';

export default yup.object({
  firstname: yup
    .string()
    .max(
      80,
      (val) => `Le prénom ne peut pas contenir plus de ${val.max} caractères`
    )
    .required('Le prénom est requis'),
  lastname: yup
    .string()
    .max(
      80,
      (val) => `Le nom ne peut pas contenir plus de ${val.max} caractères`
    )
    .required('Le nom est requis'),
  email: yup
    .string()
    .email("Le format de l'email est invalide")
    .max(
      80,
      (val) => `L'email ne peut pas contenir plus de ${val.max} caractères`
    )
    .required("L'email est requis"),
  tel: yup
    .string()
    .matches(
      /^[0-9]{10}$/,
      'Le numéro de téléphone doit être composé de 10 chiffres'
    ),
  address1: yup
    .string()
    .max(
      120,
      (val) =>
        `L'addresse (ligne 1) ne peut pas contenir plus de ${val.max} caractères`
    )
    .required("L'adresse (ligne 1) est requise"),
  address2: yup
    .string()
    .max(
      120,
      (val) =>
        `L'addresse (ligne 2) ne peut pas contenir plus de ${val.max} caractères`
    ),
  zipcode: yup
    .string()
    .matches(/^[0-9]{5}$/, 'Le code postal doit être composé de 5 chiffres')
    .required('Le code postal est requis'),
  city: yup
    .string()
    .max(
      120,
      (val) => `La ville ne peut pas contenir plus de ${val.max} caractères`
    )
    .required('La ville est requise'),
  acceptTerms: yup
    .bool()
    .oneOf([true], "Vous devez accepter d'être recontacté(e)"),
  isFromMarseille: yup.bool().oneOf([true], 'Vous devez être marseillais'),
  hasRequiredAge: yup
    .bool()
    .oneOf([true], 'Vous devez déclarer avoir entre 18 et 30 ans'),
});
