import { Container } from "@mui/material";
import MyStepper from "./components/MyStepper";

function App() {
  return (
    <Container sx={{ px: { xs: 1, sm: 3 }, py: 2 }}>
      <MyStepper />
    </Container>
  );
}

export default App;
