import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

export default function Step1({ formik }) {
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <FormControlLabel
            control={<Checkbox required />}
            label='Je déclare avoir entre 18 et 30 ans *'
            name='hasRequiredAge'
            value={formik.values.hasRequiredAge}
            onChange={formik.handleChange}
            required
            id='hasRequiredAge'
            checked={formik.values.hasRequiredAge}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={<Checkbox />}
            label='Je réside à Marseille *'
            name='isFromMarseille'
            value={formik.values.isFromMarseille}
            checked={formik.values.isFromMarseille}
            onChange={formik.handleChange}
            id='isFromMarseille'
            required
          />
        </Box>

        <Box>
          <TextField
            name='firstname'
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
            placeholder='Prénom'
            onBlur={formik.handleBlur}
            id='firstname'
            label='Prénom'
            fullWidth
            required
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box>
          <TextField
            name='lastname'
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={formik.touched.lastname && formik.errors.lastname}
            placeholder='Nom'
            onBlur={formik.handleBlur}
            id='lastname'
            label='Nom'
            fullWidth
            required
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box>
          <TextField
            name='email'
            type='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder='Email'
            onBlur={formik.handleBlur}
            id='email'
            label='Email'
            fullWidth
            required
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box>
          <TextField
            name='tel'
            value={formik.values.tel}
            onChange={formik.handleChange}
            error={formik.touched.tel && Boolean(formik.errors.tel)}
            helperText={formik.touched.tel && formik.errors.tel}
            placeholder='Téléphone'
            onBlur={formik.handleBlur}
            id='tel'
            label='Téléphone'
            fullWidth
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box>
          <TextField
            name='address1'
            value={formik.values.address1}
            onChange={formik.handleChange}
            error={formik.touched.address1 && Boolean(formik.errors.address1)}
            helperText={formik.touched.address1 && formik.errors.address1}
            placeholder='Adresse (ligne 1)'
            onBlur={formik.handleBlur}
            id='address1'
            label='Adresse (ligne 1)'
            fullWidth
            required
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box>
          <TextField
            name='address2'
            value={formik.values.address2}
            onChange={formik.handleChange}
            error={formik.touched.address2 && Boolean(formik.errors.address2)}
            helperText={formik.touched.address2 && formik.errors.address2}
            placeholder='Adresse (ligne 2)'
            onBlur={formik.handleBlur}
            id='address2'
            label='Adresse (ligne 2)'
            fullWidth
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box display='flex' justifyContent='space-between' columnGap={1}>
          <TextField
            name='zipcode'
            value={formik.values.zipcode}
            onChange={formik.handleChange}
            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
            helperText={formik.touched.zipcode && formik.errors.zipcode}
            placeholder='Code postal'
            onBlur={formik.handleBlur}
            id='zipcode'
            label='Code postal'
            fullWidth
            required
            sx={{ my: 0.7, width: 200 }}
          />
          <TextField
            name='city'
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            placeholder='Ville'
            onBlur={formik.handleBlur}
            id='city'
            label='Ville'
            fullWidth
            required
            sx={{ my: 0.7 }}
          />
        </Box>
        <Box>
          <FormControlLabel
            name='acceptTerms'
            value={formik.values.acceptTerms}
            checked={formik.values.acceptTerms}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id='acceptTerms'
            required
            control={<Checkbox />}
            label="RGPD: J'accepte d'être recontacté(e) dans le cadre de mon projet avec les carrefours de l'entreprenariat *"
            sx={{ my: 2 }}
          />
        </Box>
      </form>
      <Typography sx={{ mt: 3 }}>
        Les champs marqués d'un astérisque (*) sont obligatoires
      </Typography>
    </>
  );
}
