import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import posthog from 'posthog-js'
posthog.init('phc_R5G5gblkoQKtXDGEI9wXHRcWtNqwVjQMj6ayPBRkFEf', { api_host: 'https://eu.posthog.com' })

const App = ({
  url,
  formValues: {
    email,
    firstname,
    lastname,
    tel,
    zipcode,
    city,
    address1,
    address2,
  },
}) => {
  const [confirmed, setConfirmed] = useState(false);
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => {
      posthog.capture('event_date_selected', {email: email, url});
    },
    onEventTypeViewed: () => {
      posthog.capture('event_viewed', {email: email,  url});
    },
    onEventScheduled: (e) => {
      posthog.capture('event_booked', {email: email, url});
    },
  });
  return (
    <Grid container sx={{ md: { px: 1 } }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{ mb: 3, p: 3, border: 1, borderRadius: '6px', borderWidth: 1 }}
        >
          <FormControlLabel
            sx={{ mb: 3 }}
            control={
              <Checkbox
                name='appointmentConfirmation'
                color='primary'
                required
              />
            }
            onChange={(e) => setConfirmed(e.target.checked)}
            label="Je m'engage à me rendre au rendez-vous que je vais prendre."
          />
          <Typography sx={{ mb: 5 }}>
            En confirmant ce rendez-vous, vous vous engagez à l'honorer. Pensez
            bien à l'annuler le plus tôt possible en cas d’imprévu.
          </Typography>
          <Typography>
            Si vous souhaitez avoir un rendez-vous dans le meilleur délai, nous
            vous invitons à regarder les disponibilités de chaque Carrefour.
          </Typography>
        </Box>
      </Grid>
      {confirmed && (
        <Grid item xs={12} md={6} sx={{ height: 600 }}>
          <InlineWidget
            url={url}
            prefill={{
              email,
              name: `${firstname} ${lastname}`,
              customAnswers: {
                a2: tel,
                a3: `${address1} ${address2}, ${zipcode} ${city}`,
              },
              date: new Date(Date.now() + 86400000),
            }}
            styles={{ minWidth: 0, height: '100%' }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default App;
