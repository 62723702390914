import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { useState } from 'react';
import { useFormik } from 'formik';

import validationSchema from '../validation-schema';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';

const steps = [
  'Je saisis mes coordonées',
  "Je choisis mon Carrefour de l'entrepreuriat",
  'Je prends rendez-vous',
];

export default function MyStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [url, setUrl] = useState('');
  const [loadMapFirstTime, setLoadMapFirstTime] = useState(false);

  const handleLoadMapFirstTime = () => {
    if (loadMapFirstTime || activeStep !== 0) return;
    setLoadMapFirstTime(true);
  };

  const handleUrl = (val) => setUrl(val);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      window.scrollTo(0, 0);
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const formik = useFormik({
    initialValues: {
      acceptTerms: false,
      isFromMarseille: false,
      hasRequiredAge: false,
      firstname: '',
      lastname: '',
      email: '',
      tel: '',
      address1: '',
      address2: '',
      zipcode: '',
      city: '',
    },
    initialTouched: false,
    validationSchema: validationSchema,
    initialErrors: false,
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        sx={{ alignItems: 'flex-start', columnGap: 0.5 }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step
              key={label}
              {...stepProps}
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                padding: 0,
              }}
            >
              <StepLabel {...labelProps} sx={{ mb: 1 }}></StepLabel>
              <Typography variant='caption' textAlign='center'>
                {label}
              </Typography>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          <Grid
            container
            sx={{
              py: 4,
              px: { md: 2, lg: 4 },
            }}
          >
            {activeStep === 0 && (
              <Grid item xs={12} sm={8} md={6}>
                <Step1 formik={formik} />
              </Grid>
            )}
            {loadMapFirstTime && (
              <Grid
                item
                xs={12}
                sx={{
                  marginX: 'auto',
                  display: activeStep === 1 ? 'block' : 'none',
                  height: 700,
                }}
              >
                <Step2 handleUrl={handleUrl} />
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid item xs={12} sm={9} sx={{ ml: 'auto' }}>
                <Step3 url={url} formValues={formik.values} />
              </Grid>
            )}
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep !== 0 && (
              <Button
                color='inherit'
                variant='contained'
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Retour
              </Button>
            )}

            <Box sx={{ flex: '1 1 auto' }} />

            {activeStep !== 2 ? (
              <Button
                onClick={() => {
                  handleLoadMapFirstTime();
                  handleNext();
                }}
                variant='contained'
                disabled={
                  (activeStep === 0 && (!formik.dirty || !formik.isValid)) ||
                  (activeStep === 1 && !url)
                }
              >
                {'Etape ' + (activeStep + 2)}
              </Button>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
}
