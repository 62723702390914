/* eslint-disable */

import { useState } from "react";

const Step2 = ({ handleUrl }) => {
  const [selected, setSelected] = useState(null);
  const [allMarkers, setAllMarkers] = useState([]);
  const handleSelected = (i) => setSelected(i);
  const handleSetAllMarkers = (markers) => setAllMarkers(markers);

  if (selected !== null) {
    allMarkers.forEach((m, i) => {
      if (i == selected) {
        m.setIcon({
          url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          scaledSize: new google.maps.Size(60, 60),
        });
      } else {
        m.setIcon(undefined);
      }
    });
  }

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  waitForElm("#map").then((elm) => {
    const mapDiv = document.getElementById("map");
    if (!mapDiv.innerHTML) {
      const existingScript = document.getElementById("googleMaps");
      if (existingScript) existingScript.remove();

      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDJTodLQckaUAh0jsIE1TGwhC9y_NW6dUA&callback=initMap&libraries=places,geometry&solution_channel=GMP_QB_locatorplus_v4_cABD";
      script.id = "googleMaps";
      document.body.appendChild(script);
    }
  });

  function LocatorPlus(configuration) {
    const locator = this;

    locator.locations = configuration.locations || [];
    locator.capabilities = configuration.capabilities || {};

    const mapEl = document.getElementById("map");
    const panelEl = document.getElementById("locations-panel");
    locator.panelListEl = document.getElementById("locations-panel-list");
    const sectionNameEl = document.getElementById(
      "location-results-section-name"
    );
    const resultsContainerEl = document.getElementById("location-results-list");

    const itemsTemplate = Handlebars.compile(
      document.getElementById("locator-result-items-tmpl").innerHTML
    );

    locator.searchLocation = null;
    locator.searchLocationMarker = null;
    locator.selectedLocationIdx = null;
    locator.userCountry = null;

    // Initialize the map -------------------------------------------------------
    locator.map = new google.maps.Map(mapEl, configuration.mapOptions);

    // Store selection.
    const selectResultItem = function (
      locationIdx,
      panToMarker,
      scrollToResult
    ) {
      locator.selectedLocationIdx = locationIdx;
      for (let locationElem of resultsContainerEl.children) {
        locationElem.classList.remove("selected");
        if (getResultIndex(locationElem) === locator.selectedLocationIdx) {
          locationElem.classList.add("selected");
          if (scrollToResult) {
            panelEl.scrollTop = locationElem.offsetTop;
          }
        }
      }
      if (panToMarker && locationIdx != null) {
        locator.map.panTo(locator.locations[locationIdx].coords);
      }
    };

    const markers = locator.locations.map(function (location, index) {
      const marker = new google.maps.Marker({
        position: location.coords,
        map: locator.map,
        title: location.title,
        scaledSize: new google.maps.Size(200, 200),
      });
      marker.addListener("click", function () {
        selectResultItem(index, false, true);
        handleUrl(CONFIGURATION.locations[index].urlRdv);
        handleSelected(index);
      });
      return marker;
    });
    handleSetAllMarkers(markers);
    // Fit map to marker bounds.
    locator.updateBounds = function () {
      const bounds = new google.maps.LatLngBounds();
      if (locator.searchLocationMarker) {
        bounds.extend(locator.searchLocationMarker.getPosition());
      }
      for (let i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
      }
      locator.map.fitBounds(bounds);
    };
    if (locator.locations.length) {
      locator.updateBounds();
    }

    // Get the distance of a store location to the user's location,
    // used in sorting the list.
    const getLocationDistance = function (location) {
      if (!locator.searchLocation) return null;

      // Use travel distance if available (from Distance Matrix).
      if (location.travelDistanceValue != null) {
        return location.travelDistanceValue;
      }

      // Fall back to straight-line distance.
      return google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(location.coords),
        locator.searchLocation.location
      );
    };

    // Render the results list --------------------------------------------------
    const getResultIndex = function (elem) {
      return parseInt(elem.getAttribute("data-location-index"));
    };

    locator.renderResultsList = function () {
      let locations = locator.locations.slice();
      for (let i = 0; i < locations.length; i++) {
        locations[i].index = i;
      }
      if (locator.searchLocation) {
        sectionNameEl.textContent =
          "Nearest locations (" + locations.length + ")";
        locations.sort(function (a, b) {
          return getLocationDistance(a) - getLocationDistance(b);
        });
      } else {
        sectionNameEl.textContent = `Tous les carrefours (${locations.length})`;
      }
      const resultItemContext = { locations: locations };
      resultsContainerEl.innerHTML = itemsTemplate(resultItemContext);
      for (let item of resultsContainerEl.children) {
        const resultIndex = getResultIndex(item);
        if (resultIndex === locator.selectedLocationIdx) {
          item.classList.add("selected");
        }

        const resultSelectionHandler = function () {
          selectResultItem(resultIndex, true, false);
          handleUrl(CONFIGURATION.locations[resultIndex].urlRdv);
          handleSelected(resultIndex);
        };

        // Clicking anywhere on the item selects this location.
        // Additionally, create a button element to make this behavior
        // accessible under tab navigation.
        item.addEventListener("click", resultSelectionHandler);
        item
          .querySelector(".select-location")
          .addEventListener("click", function (e) {
            resultSelectionHandler();
            e.stopPropagation();
          });
      }
    };

    // Optional capability initialization --------------------------------------
    initializeSearchInput(locator);
    initializeDistanceMatrix(locator);

    // Initial render of results -----------------------------------------------
    locator.renderResultsList();
  }

  /** When the search input capability is enabled, initialize it. */
  function initializeSearchInput(locator) {
    const geocodeCache = new Map();
    const geocoder = new google.maps.Geocoder();

    const searchInputEl = document.getElementById("location-search-input");
    const searchButtonEl = document.getElementById("location-search-button");

    const updateSearchLocation = function (address, location) {
      if (locator.searchLocationMarker) {
        locator.searchLocationMarker.setMap(null);
      }
      if (!location) {
        locator.searchLocation = null;
        return;
      }
      locator.searchLocation = { address: address, location: location };
      locator.searchLocationMarker = new google.maps.Marker({
        position: location,
        map: locator.map,
        title: "My location",
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 12,
          fillColor: "#fff",
          fillOpacity: 0.5,
          strokeOpacity: 0,
        },
      });

      // Update the locator's idea of the user's country, used for units. Use
      // `formatted_address` instead of the more structured `address_components`
      // to avoid an additional billed call.
      const addressParts = address.split(" ");
      locator.userCountry = addressParts[addressParts.length - 1];

      // Update map bounds to include the new location marker.
      locator.updateBounds();

      // Update the result list so we can sort it by proximity.
      locator.renderResultsList();

      locator.updateTravelTimes();
    };

    const geocodeSearch = function (query) {
      if (!query) {
        return;
      }

      const handleResult = function (geocodeResult) {
        searchInputEl.value = geocodeResult.formatted_address;
        updateSearchLocation(
          geocodeResult.formatted_address,
          geocodeResult.geometry.location
        );
      };

      if (geocodeCache.has(query)) {
        handleResult(geocodeCache.get(query));
        return;
      }
      const request = { address: query, bounds: locator.map.getBounds() };
      geocoder.geocode(request, function (results, status) {
        if (status === "OK") {
          if (results.length > 0) {
            const result = results[0];
            geocodeCache.set(query, result);
            handleResult(result);
          }
        }
      });
    };

    // Set up geocoding on the search input.
    searchButtonEl.addEventListener("click", function () {
      geocodeSearch(searchInputEl.value.trim());
    });

    // Initialize Autocomplete.
    initializeSearchInputAutocomplete(
      locator,
      searchInputEl,
      geocodeSearch,
      updateSearchLocation
    );
  }

  /** Add Autocomplete to the search input. */
  function initializeSearchInputAutocomplete(
    locator,
    searchInputEl,
    fallbackSearch,
    searchLocationUpdater
  ) {
    // Set up Autocomplete on the search input. Bias results to map viewport.
    const autocomplete = new google.maps.places.Autocomplete(searchInputEl, {
      types: ["geocode"],
      fields: ["place_id", "formatted_address", "geometry.location"],
    });
    autocomplete.bindTo("bounds", locator.map);
    autocomplete.addListener("place_changed", function () {
      const placeResult = autocomplete.getPlace();
      if (!placeResult.geometry) {
        // Hitting 'Enter' without selecting a suggestion will result in a
        // placeResult with only the text input value as the 'name' field.
        fallbackSearch(placeResult.name);
        return;
      }
      searchLocationUpdater(
        placeResult.formatted_address,
        placeResult.geometry.location
      );
    });
  }

  /** Initialize Distance Matrix for the locator. */
  function initializeDistanceMatrix(locator) {
    const distanceMatrixService = new google.maps.DistanceMatrixService();

    // Annotate travel times to the selected location using Distance Matrix.
    locator.updateTravelTimes = function () {
      if (!locator.searchLocation) return;

      const units =
        locator.userCountry === "FRA"
          ? google.maps.UnitSystem.IMPERIAL
          : google.maps.UnitSystem.METRIC;
      const request = {
        origins: [locator.searchLocation.location],
        destinations: locator.locations.map(function (x) {
          return x.coords;
        }),
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: units,
      };
      const callback = function (response, status) {
        if (status === "OK") {
          const distances = response.rows[0].elements;
          for (let i = 0; i < distances.length; i++) {
            const distResult = distances[i];
            let travelDistanceText, travelDistanceValue;
            if (distResult.status === "OK") {
              travelDistanceText = distResult.distance.text;
              travelDistanceValue = distResult.distance.value;
            }
            const location = locator.locations[i];
            location.travelDistanceText = travelDistanceText;
            location.travelDistanceValue = travelDistanceValue;
          }

          // Re-render the results list, in case the ordering has changed.
          locator.renderResultsList();
        }
      };
      distanceMatrixService.getDistanceMatrix(request, callback);
    };
  }
  var lat = 38.0;
  var lng = -100;

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    var crd = pos.coords;
    if (crd.latitude !== undefined) {
      lat = crd.latitude;
    }
    if (crd.longitude !== undefined) {
      lng = crd.longitude;
    }
    /*
  console.log('Your current position is:');
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
  console.log(`Latitude : ${lat}`);
  console.log(`Longitude: ${lng}`);
  */
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  navigator.geolocation.getCurrentPosition(success, error, options);

  const CONFIGURATION = {
    locations: [
      {
        title: "Le Carburateur - Pôle de l'Entrepreneuriat",
        address1: "211 Chem. de la Madrague-Ville",
        address2: "13015 Marseille",
        coords: { lat: 43.3314648, lng: 5.3566692 },
        placeId: "ChIJR4qudndLx4ARVLDye3zwycw1",
        urlRdv: "https://calendly.com/lecarbu/rdvdiagnostic",
      },
      {
        title: "Instinct Biz'",
        address1: "1 rue Saint-Sébastien",
        address2: "13006 Marseille",
        coords: { lat: 43.286080223397555, lng: 5.382381298247194 },
        placeId: "ChIJR4qudndLx4ARVLDye3zwycw8",
        urlRdv: "https://calendly.com/instinct-biz/calendrier-1",
      },
      {
        title: " Le TransfOrama",
        address1: "La Friche Belle de Mai, 41 Rue Jobin",
        address2: "13003 Marseille",
        coords: { lat: 43.3101759, lng: 5.3883806 },
        placeId: "ChIJR4qudndLx4ARVLDye3zwycw6",
        urlRdv: "https://calendly.com/transforama/30min",
      },
      {
        title: " Le Spot",
        address1: "4 rue Berthelot",
        address2: "13014 Marseille",
        coords: { lat: 43.3371383, lng: 5.3900077 },
        placeId: "ChIJR4qudndLx4ARVLDye3zwycw7",
        urlRdv: "https://calendly.com/les-revelatrices-du-spot",
      },
    ],
    mapOptions: {
      center: { lat: lat, lng: lng },
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      zoom: 4,
      zoomControl: true,
      maxZoom: 17,
    },
    mapsApiKey: "AIzaSyDJTodLQckaUAh0jsIE1TGwhC9y_NW6dUA",
    capabilities: {
      input: true,
      autocomplete: true,
      directions: false,
      distanceMatrix: true,
      details: false,
    },
  };

  window.initMap = function () {
    new LocatorPlus(CONFIGURATION);
  };

  return (
    <div id="map-container">
      <div id="locations-panel">
        <div id="locations-panel-list">
          <header>
            <h1 className="search-title">
              <img src="https://fonts.gstatic.com/s/i/googlematerialicons/place/v15/24px.svg" />
              Trouvez un carrefour à côté de chez vous
            </h1>
            <div className="search-input">
              <input
                id="location-search-input"
                placeholder="Saisiez votre adresse ou votre code postal"
              />
              <div
                id="search-overlay-search"
                className="search-input-overlay search"
              >
                <button id="location-search-button">
                  <img
                    className="icon"
                    src="https://fonts.gstatic.com/s/i/googlematerialicons/search/v11/24px.svg"
                    alt="Search"
                  />
                </button>
              </div>
            </div>
          </header>
          <div className="section-name" id="location-results-section-name">
            Tous les centres
          </div>
          <div className="results">
            <ul id="location-results-list"></ul>
          </div>
        </div>
      </div>
      <div id="map"></div>
    </div>
  );
};

export default Step2;
